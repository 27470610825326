import React from "react";
import { useStaticQuery, graphql } from 'gatsby'

const StockistsMain = () => {
    const data = useStaticQuery(graphql`
    {
      allPagesJson(filter: { title: {eq: "Stockists"} }) {
        edges {
          node {
            title
            text
          }
        }
      }
    }
  `)

    return (
        <>
            {data.allPagesJson.edges.map(edge => (
                <div key={edge.id}>
                  <div>
                    <h1 style={{marginTop: '-1rem'}}>{edge.node.title}</h1>
                    <p>{edge.node.text}</p>
                  </div>
                </div>
            ))} 
        </>
    )
  }
  
export default StockistsMain