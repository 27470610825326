import React from "react"
import { graphql } from "gatsby"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"
import MarkerClusterGroup from "react-leaflet-markercluster"
import AddLocate from "../lib/add-locate"
import { Helmet } from "react-helmet"
import Layout from '../components/layout'
import { Link } from 'gatsby'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import StockistsMain from '../components/map-content'
import Separator from "../components/separator"
import "react-leaflet-markercluster/dist/styles.min.css"
import {
    buttonsContainer
  } from'../lib/buttons.module.css'

const Stockists = ({ data: { posts } }) => {
    return (
      <>
        <Helmet>
            <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
            />
        </Helmet>
        <Layout pageTitle="Stockists">
        <MapContainer
            center={[53.5631592, -1.5540773]}
            zoom={6}
            style={{ height: "62vh" }}
        >
            <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://api.mapbox.com/styles/v1/giobyron/ckzsmwu8e00s714p8ko1a6bn0/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ2lvYnlyb24iLCJhIjoiY2t6c2wwejczNGQxcTJ5bnI2YnRpZXdnaCJ9.JYDEvwW5nRGJFPW5ogH0UA"
            />
            <MarkerClusterGroup>
            {
                posts.nodes.map(post => {
                    const { id, location, name, address, phone, email, website } = post;
                    const {latitude, longitude} = location;
                    const position = [latitude, longitude];
                    return (
                        <Marker key={id} position={position}>
                            <Popup>
                                <h2>{ name }</h2>
                                <p>{ address }</p>
                                <p>
                                    Phone: { phone }<br />
                                    Email: <a href={ 'mailto:' + email }>{ email }</a><br />
                                    Website: <a href={'http://' + website } target="_blank">{ website }</a>
                                </p>
                            </Popup>
                        </Marker>
                    )
                })
            }
            </MarkerClusterGroup>
            <AddLocate /> 
        </MapContainer>
        <div className={buttonsContainer}>
            <div><Link to="/collections">Collections</Link></div>
            <div><AnchorLink to="#contact" title="Get in touch">Get in touch</AnchorLink></div>
        </div>
        <StockistsMain />
        <Separator />
        </Layout>
    </>
    )
}

export const retailerQuery = graphql`
query {
  posts: allGraphCmsRetailer {
    nodes {
      id
      name
      address
      phone
      email
      website
      location {
        latitude
        longitude
      }
    }
  }
}
`

export default Stockists
